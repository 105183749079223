<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="!popupParam.plantCd">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="작업장소 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      selection="single"
      rowKey="sopMapId"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :isExcelDown="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'location-pop',
  props: {
    popupParam: {
      type: Object,
      default: function () {
        return {
          plantCd: '',
        };
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function () {
        return 'auto';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '공사현장',
            align: 'center',
            style: 'width: 300px',
            sortable: true,
          },
          {
            name: 'mapName',
            field: 'mapName',
            label: '작업장소명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '설명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '450px',
      },
      searchParam: {
        plantCd: null,
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  watch: {
    contentHeight() {
      let height =
        this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50;
      if (height < 400) {
        height = 450;
      }
      this.grid.height = height + 'px';
    },
  },
  methods: {
    init() {
      this.listUrl = selectConfig.sop.swp.map.list.url;
      if(this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      });
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '작업장소를 선택하세요.', // 설비를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  },
};
</script>
